<template>
  <button class="btn" :class="variantClass" @click="(e) => $emit('click', e)" :disabled="disabled">
    <b-spinner v-if="loading" :type="type" small class="mr-2"></b-spinner>
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'grow'
    },
    variant: {
      type: String
    },
    disabled: {
      type: Boolean
    },
    loading: {
      type: Boolean
    }
  },
  computed: {
    variantClass () {
      return this.variant ? `btn-${this.variant}` : 'btn-primary'
    }
  }
}
</script>
