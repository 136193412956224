<template>
  <app-layout>
    <div class="px-4 px-sm-5 container">
      <div class="heading d-flex justify-content-between mb-5">
        <h2>Compte bancaire</h2>
      </div>

      <div class="alert alert-danger" v-if="hasError">
        Une erreur s'est produite. Vérifiez vos informations bancaires, puis réessayez.
      </div>

      <div class="row">
        <div class="col-12">
          <div class="mb-4">
            <router-link class="btn btn-link btn-sm" to="/bank-accounts" active-class="active">
              <fa-icon :icon="['fas', 'chevron-left']" size="sm"></fa-icon>&nbsp;Retour
            </router-link>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="card mb-4">
            <div class="card-header">Informations du compte</div>
            <div class="card-body">
              <v-dynamic-form
                :fields="fields"
                :initial-values="defaultValues"
                @change="handleChange"
                :errors="formErrors"
              ></v-dynamic-form>
            </div>
          </div>

          <div class="mb-4">
            <button-loader :disabled="isProcessing" :loading="isProcessing" @click="handleSubmit">
              Enregistrer
            </button-loader>
          </div>
        </div>
      </div>

    </div>
  </app-layout>
</template>

<script>
import handleForm from '@/mixins/handle-form'
import userData from '@/mixins/user-data'
import useValidator from '@/mixins/useValidator'
import http from '@/utils/http'
import promptUnsaved from '@/mixins/promptUnsaved'
import ButtonLoader from '@/components/common/ButtonLoader'

export default {
  mixins: [userData, handleForm, useValidator, promptUnsaved],
  components: {
    ButtonLoader
  },
  data () {
    return {
      form: {},
      isProcessing: false,
      showFormErrors: false,
      hasError: false
    }
  },
  computed: {
    fields () {
      return [
        {
          name: 'accountName',
          label: 'Nom du titulaire du compte',
          required: true
        },
        {
          name: 'transitNumber',
          label: 'Numéro de transit',
          required: true,
          helpText: 'Numéro à 5 chiffres'
        },
        {
          name: 'institutionNumber',
          label: "Numéro d'institution",
          required: true,
          helpText: 'Numéro à 3 chiffres'
        },
        {
          name: 'accountNumber',
          label: 'Numéro de compte',
          required: true
        }
      ]
    },
    formErrors () {
      return this.showFormErrors ? this.errors : {}
    },
    defaultValues () {
      return {}
    },
    validationRules () {
      return {
        accountName: 'required|min:2',
        transitNumber: 'required|bank-transit',
        institutionNumber: 'required|bank-institution',
        accountNumber: 'required|bank-account'
      }
    }
  },
  methods: {
    handleChange (values) {
      if (Object.entries(this.form).length > 0) {
        this.formStatusChanged(true)
      }
      this.validate(values, this.validationRules)
      this.form = { ...values }
    },
    async handleSubmit () {
      this.showFormErrors = Object.values(this.errors).length > 0

      if (this.showFormErrors) {
        return
      }

      this.isProcessing = true
      this.hasError = false

      try {
        await http.put(`/v1/stores/${this.currentStoreId}/bank-accounts`, { ...this.form })

        this.formStatusChanged(false)
        this.$router.push({ name: 'bankAccounts.index' })
      } catch (e) {
        this.hasError = true
        this.isProcessing = false
      }
    }
  }
}
</script>
