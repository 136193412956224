<template>
  <app-layout>
    <div class="px-4 p-sm-5 container">
      <div class="heading d-flex justify-content-between mb-5">
        <h2>Compte bancaire</h2>
      </div>

      <template v-if="isLoading">
        <b-spinner></b-spinner>
      </template>

      <template v-else>
        <div class="alert alert-danger" v-if="hasError">
          Une erreur interne s'est produite. Réessayez dans quelques instants.
        </div>
        <div class="alert alert-warning" v-if="hasWarning">
          Aucun profil n'a été configuré pour votre boutique, veuillez compléter <router-link class="link-warning" to="/settings/profile">votre profil d'entreprise</router-link> avant de configurer votre compte bancaire.
        </div>

        <div class="row">
          <div class="col-12">
            <div class="mb-4">
              <button class="btn btn-primary btn-sm" @click="$router.push({ name: 'bankAccounts.update' })" :disabled="hasWarning">Modifier le compte bancaire</button>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="card mb-4">
              <div v-if="!account" class="card-body">
                <empty-state :src="require('@/assets/images/icons/bank.svg')" message="Vous n'avez aucun compte associé" />
              </div>
              <div v-else class="card-body">
                <dl class="row">
                  <dt class="col-5">Nom du titulaire :</dt>
                  <dd class="col-7">{{ account.account_holder_name }}</dd>
                </dl>
                <dl class="row">
                  <dt class="col-5">Banque :</dt>
                  <dd class="col-7">{{ account.bank_name }}</dd>
                </dl>
                <dl class="row">
                  <dt class="col-5">Numéro de compte :</dt>
                  <dd class="col-7">**** **** {{ account.last4 }}</dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </template>

    </div>
  </app-layout>
</template>

<script>
import userData from '@/mixins/user-data'
import http from '@/utils/http'
import EmptyState from '@/components/common/EmptyState'

export default {
  mixins: [userData],
  components: { EmptyState },
  data () {
    return {
      isLoading: true,
      hasError: false,
      hasWarning: false,
      account: null
    }
  },
  async created () {
    try {
      this.isLoading = true
      this.hasError = false
      this.hasWarning = false

      const { data: account } = await http.get(`/v1/stores/${this.currentStoreId}/bank-accounts`)

      this.account = account
    } catch (e) {
      console.error('Error', e)
      if (e.response.status === 404 || e.response.data.toLowerCase().includes('profile not found')) {
        this.hasWarning = true
      } else {
        this.hasError = true
      }
    } finally {
      this.isLoading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.link-warning {
  color: inherit;
  text-decoration: underline;
  font-weight: 600;
}
</style>
