import Vue from 'vue'

export default Vue.extend({
  data () {
    return {
      hasPendingChanges: false
    }
  },
  methods: {
    formStatusChanged (state: boolean) {
      this.hasPendingChanges = state
    },
    canRefresh () {
      return (!this.hasPendingChanges) ? null : 'Les modifications que vous avez apportées ne seront peut-être pas enregistrées.'
    },
    async canLeave () {
      if (this.hasPendingChanges) {
        try {
          await this.$modal.openConfirmModal({
            title: 'Quitter la page',
            message: 'Les modifications que vous avez apportées ne seront peut-être pas enregistrées. Voulez-vous quitter la page?',
            confirmLabel: 'Confirmer',
            cancelLabel: 'Annuler'
          })

          return true
        } catch (e) {
          return false
        }
      }

      return true
    }
  },
  async beforeRouteLeave (to, from, next) {
    if (await this.canLeave()) {
      next()
    } else {
      next(false)
    }
  },
  beforeDestroy () {
    window.onbeforeunload = () => { return null }
  },
  created () {
    window.onbeforeunload = this.canRefresh
  }
})
